import EditorWrapper from './EditorWrapper';
import {
  ABOUT_APP_ID,
  ABOUT_PAGE,
  GROUP_MEMBERS_AREA_PAGE,
  GROUP_PAGE_ID,
  GROUP_PAGES,
  GROUPS_MEMBERS_AREA_PAGE_ID,
  GROUPS_PAGE_ID,
  MEMBERS_APP_DEF_ID,
  NOTIFICATIONS_APP_ID,
  NOTIFICATIONS_PAGE,
  SETTINGS_PAGE,
  SITE_MEMBERS_APP_ID,
  SITE_MEMBERS_PAGE,
} from '../app-configs';
import { MembersApi } from './types/MembersApi';
import { GroupsAppSpec } from './types/AppSpec';
import { Translations } from './Translations';
import { getGroupsManifest } from './manifest';
import AppManifest from './types/manifest';
import { pageSettingsUrls } from '../pages-settings/pageSettingsUrls';
import { PageData, TPARef } from './types/common';

class GroupPlatform {
  private readonly editor: EditorWrapper;

  constructor(editorSDK, public readonly appDefId: string) {
    this.editor = new EditorWrapper(editorSDK, appDefId);
  }

  async install() {
    await this.setupGroupsPages();
  }

  async installMembers() {
    console.groupCollapsed('Members install');
    await this.editor.installApplication(MEMBERS_APP_DEF_ID);
    console.groupEnd();
  }

  private async setupGroupsPages() {
    const [tpaData, allSitePages] = await Promise.all([
      this.editor.getDataByAppDefId(this.appDefId),
      this.editor.getAllPages(),
    ]);
    const { applicationId } = tpaData;

    const groupPages = this.getGroupsTPARefs(allSitePages, applicationId);
    await this.editor.addToManagedPages(groupPages);
    await this.editor.save();
  }

  async setGroupPageState(applicationId: string) {
    try {
      const allSitePages = await this.editor.getAllPages();
      const groupPage: PageData = allSitePages.find(page => {
        return (
          page.managingAppDefId === applicationId &&
          page.tpaPageId === GROUP_PAGE_ID
        );
      });
      const { id } = groupPage;
      await this.editor.setPageState({ groupPage: [{ id }] });
    } catch (e) {
      console.error('Set group page state: FAIL');
    }
  }

  // For sites where groups members area was installed
  // TODO: 🚨 remove later see https://jira.wixpress.com/browse/GROUP-673
  async deleteGroupsMemberArea() {
    try {
      const allSitePages = await this.editor.getAllPages();
      const groupPage: PageData = allSitePages.find(page => {
        return page.tpaPageId === GROUPS_MEMBERS_AREA_PAGE_ID;
      });
      if (groupPage) {
        const membersApi = await this.getMembersApi();
        await membersApi.removeMembersAreaPage(GROUPS_MEMBERS_AREA_PAGE_ID);
      }
    } catch (e) {
      console.error('Remove group member area page: FAIL');
    }
  }

  private getGroupsTPARefs(allSitePages, applicationId) {
    const groupPages: TPARef[] = allSitePages
      .filter(
        page =>
          page.tpaApplicationId === applicationId &&
          GROUP_PAGES.includes(page.tpaPageId),
      )
      .map(({ id, tpaPageId }) => {
        return { title: tpaPageId, pageRef: { id } };
      });
    return groupPages;
  }

  async installMembersApps() {
    const membersApi = await this.getMembersApi();
    await this.installMembersSections(membersApi);
    await this.setMembersDependantApps(membersApi);
  }

  async installMembersSections(membersAPI: MembersApi) {
    // TODO: return GROUP_MEMBERS_AREA_PAGE installation later
    //  see https://jira.wixpress.com/browse/GROUP-673
    await membersAPI.addApplications([
      ABOUT_PAGE,
      NOTIFICATIONS_PAGE,
      SETTINGS_PAGE,
      SITE_MEMBERS_PAGE,
    ]);
  }

  private async getMembersApi() {
    return (await this.editor.getAPI(MEMBERS_APP_DEF_ID)) as MembersApi;
  }

  async setMembersDependantApps(membersAPI: MembersApi) {
    await membersAPI.setDependantApps({
      appId: this.appDefId,
      dependencies: [ABOUT_APP_ID, SITE_MEMBERS_APP_ID, NOTIFICATIONS_APP_ID],
    });
  }

  async getManifest(): Promise<AppManifest> {
    const {
      appFields: {
        platform: { baseUrls },
      },
    } = await this.editor.getAppData<GroupsAppSpec>();
    const baseUrl = baseUrls.staticsEditorBaseUrl;
    const locale = await this.editor.getLocale();
    const translation = new Translations();
    await translation.editorInit(locale, baseUrl);
    return getGroupsManifest(translation, pageSettingsUrls(baseUrl));
  }

  addPage() {
    return this.editor.addPage();
  }

  private getGroupsTPARef(allSitePages, applicationId) {
    const groupsPage: PageData = allSitePages.find(
      page =>
        page.tpaApplicationId === applicationId &&
        page.tpaPageId === GROUPS_PAGE_ID,
    );
    return { title: GROUPS_PAGE_ID, pageRef: { id: groupsPage.id } };
  }

  async deleteApp() {
    const [tpaData, allSitePages] = await Promise.all([
      this.editor.getDataByAppDefId(this.appDefId),
      this.editor.getAllPages(),
    ]);
    const { applicationId } = tpaData;
    const groupsPage = this.getGroupsTPARef(allSitePages, applicationId);
    if (groupsPage) {
      return this.editor.deletePage(groupsPage);
    }
  }

  handleInstallError() {}
}

export default GroupPlatform;
